export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard-ecommerce',
  },
  {
    title: 'PPP',
    route: 'isp-username-list',
    icon: 'WifiIcon',
  },
  {
    title: 'Invoice',
    route: 'isp-invoice-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Router Setting',
    route: 'isp-router-list',
    icon: 'ZapIcon',
  },
  {
    title: 'Package Setting',
    route: 'isp-package-list',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Mobile Banking',
    route: 'mobile-banking-payment-setting',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Report',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Date wise Report',
        route: 'isp-date-wise-report',
      },
      {
        title: 'New Connection Report',
        route: 'isp-new-connection-report',
      },
      {
        title: 'Un-Invoiced Report',
        route: 'isp-un-invoiced-report',
      },
      {
        title: 'Duplicate Device Report',
        route: 'isp-duplicate-device',
      },
    ],
  },
  {
    title: 'Settlement',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Settlement List',
        route: 'isp-settlement-list',
      },
      {
        title: 'Unsettlement List',
        route: 'isp-un-settlement-list',
      },
    ],
  },
  {
    title: 'User Password',
    route: 'isp-user-password-change',
    icon: 'EyeOffIcon',
  },
  {
    title: 'Monthly Bill',
    route: 'service-charge-package-list',
    icon: 'LifeBuoyIcon',
  },
  {
    title: 'Payment History',
    route: 'service-payment-history',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Bulk Notification',
    route: 'isp-bulk-notification',
    icon: 'BellIcon',
  },
]
